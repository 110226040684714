import React, { FC } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo-pricing";
import Pricing from "../components/pricing";

const IndexPage: FC = () => (
  <Layout header footer={false} menuState="delivery" cart>
    <SEO title="Tarifs" />
    <Pricing />
  </Layout>
);

export default IndexPage
