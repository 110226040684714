import React, { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { AppState } from "../../store";
import { ProductInCart } from "../../store/shopping-cart/types";

import { Product } from "../../types/product";
import { ProductListContainer } from "./style/productList";
import ProductLine from "./product-line";

interface ProductListProps {
  products: Product[];
}

const ProductList: FC<ProductListProps> = ({ products }) => {
  const productsInCart = useSelector<AppState, ProductInCart[]>(
    state => state.shoppingCart.products
  );

  const [productsWithNumber] = useMemo<[ProductInCart[], string | null]>(() => {
    let productSelectedId = '';

    return [
      products.map<ProductInCart>((product: Product) => {
        const productInCart = productsInCart.find(
          (f: ProductInCart) => f.product.id === product.id
        );

        if (productInCart) {
          productSelectedId = product.id;

          return { ...productInCart };
        }

        return { product: product, number: 0 };
      }),
      productSelectedId,
    ];
  }, [products, productsInCart]);

  return (
    <ProductListContainer>
      {products.length > 0 ? (
        productsWithNumber.map(({ product, number }: ProductInCart) => {
          return (
            <ProductLine
              key={`category-${product.id}`}
              product={product}
              number={number}
            />
          );
        })
      ) : (
        <FormattedMessage id="pricing.noresult" />
      )}
    </ProductListContainer>
  );
};

export default ProductList;
