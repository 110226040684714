import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import {
  Container,
  ContentWrapper,
  InnerWrapper,
  LabelInputContainer,
  SearchInput,
  SearchInputContainer,
  SubTitle,
  CategoryTitleContainer,
  CategorySubTitle,
  TitleContainer,
  Title,
  Wrapper,
  SpinnerContainer,
} from "./style";
import CartWidget from "../shopping-cart/cart-widget";
import { AppState } from "../../store";
import Label from "../../images/label.svg";
import { Product } from "../../types/product";
import useProducts from "./use-products";
import Spinner from "../../images/spinner.svg";
import CategoryList from "./categoryList";
import ProductList from "./productList";
import userLanguage from "../hooks/useLanguage";

const Pricing: FC = () => {
  const products = useSelector<AppState, Product[]>(
    state => state.products.allProducts
  );
  const luxeProducts = useSelector<AppState, Product[]>(
    state => state.products.luxeProducts
  );
  const intl = useIntl();
  const loading = useProducts({ luxe: false });
  const [filteredProducts, setFilteredProducts] = useState(products);
  const [searching, setSearching] = useState<boolean>(false);
  const [placeholderSearchInput, setPlaceholderSearchInput] = useState("");

  const search = useCallback(
    ({ target: { value } }) => {
      setSearching(!!value);
      setFilteredProducts(
        products.filter(({ name }) =>
          name.toLowerCase().includes(value.toLowerCase())
        )
      );
    },
    [products, luxeProducts, setFilteredProducts]
  );

  const productTree = useMemo(() => {
    return products.reduce(
      (acc: { [key: string]: Product[] }, elt: Product) => {
        if (acc[elt.category]) {
          acc[elt.category].push(elt);
        } else {
          acc[elt.category] = [elt];
        }
        return acc;
      },
      {}
    );
  }, [products]);

  const categories = useMemo(() => Object.keys(productTree), [productTree]);
  const userLang = userLanguage();

  useEffect(() => {
    setPlaceholderSearchInput(
      intl.formatMessage({
        id: "pricing.search.placeholder",
      })
    );
  }, [intl, userLang]);

  return (
    <Wrapper>
      <InnerWrapper>
        <ContentWrapper>
          <TitleContainer>
            <Title>
              <FormattedMessage id="pricing.title" />
            </Title>
            <SubTitle>
              <FormattedMessage id="home.services.text" />
            </SubTitle>
          </TitleContainer>
          <SearchInputContainer>
            <SearchInput
              placeholder={placeholderSearchInput}
              onChange={search}
            />
            <LabelInputContainer>
              <Label />
            </LabelInputContainer>
          </SearchInputContainer>
          <Container>
            <CartWidget cart fromMenu={false} />
            <CategoryTitleContainer>
              <Title>
                <FormattedMessage id="pricing.title.highquality" />
              </Title>
              <CategorySubTitle>
                <FormattedMessage id="pricing.subtitle.highquality" />
              </CategorySubTitle>
            </CategoryTitleContainer>
            {loading.loading ? (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            ) : searching ? (
              <ProductList products={filteredProducts} />
            ) : (
              <CategoryList
                luxe={false}
                categories={categories}
                products={productTree}
              />
            )}
          </Container>
        </ContentWrapper>
      </InnerWrapper>
    </Wrapper>
  );
};

export default Pricing;
