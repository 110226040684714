import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";

import { get, HttpResponse } from "../../utils/http";
import { ReduxDispatch } from "../../hooks/use-thunk-dispatch";

import { SET_CATEGORIES, SET_PRODUCTS, SET_LUXE_CATEGORIES, SET_LUXE_PRODUCTS, ProductsActionTypes } from "./types";
import { AppState } from "..";
import { Product } from "../../types/product";
import { Category } from "../../types/category";

interface GetCategoriesResponse {
  categories: Category[];
}

interface GetLuxeCategoriesResponse {
  categories: Category[];
}

interface GetProductsResponse {
  products: Product[];
}

interface GetLuxeProductsResponse {
  products: Product[];
}

type ProductActionResponse<T> = Promise<HttpResponse<T> | void>;

export const setCategories = (categories: Category[]): ProductsActionTypes => ({
  type: SET_CATEGORIES,
  categories,
});

export const setLuxeCategories = (categories: Category[]): ProductsActionTypes => ({
  type: SET_LUXE_CATEGORIES,
  categories,
});

export const setProducts = (products: Product[]): ProductsActionTypes => ({
  type: SET_PRODUCTS,
  products,
});

export const setLuxeProducts = (products: Product[]): ProductsActionTypes => ({
  type: SET_LUXE_PRODUCTS,
  products,
});

export const getCategories = ():
  ThunkAction<
    ProductActionResponse<GetCategoriesResponse>,
    AppState,
    {},
    AnyAction
  > => async (dispatch: ReduxDispatch, getState): ProductActionResponse<GetCategoriesResponse> => {
    try {
      if (Object.keys(getState().products.categories).length === 0) {
        const response = await get<GetCategoriesResponse>('/categories-website', getState());
        if (response.parsedBody) {
          const { categories } = response.parsedBody;
          dispatch(setCategories(categories));
        }

        return response;
      }
    } catch (error) {
      return error;
    }
  };

export const getLuxeCategories = (): ThunkAction<
  ProductActionResponse<GetLuxeCategoriesResponse>,
  AppState,
  {},
  AnyAction
> => async (dispatch: ReduxDispatch, getState): ProductActionResponse<GetLuxeCategoriesResponse> => {
  try {
    if (Object.keys(getState().products.luxeCategories).length === 0) {
      const response = await get<GetLuxeCategoriesResponse>('/categories/luxe', getState());
      if (response.parsedBody) {
        const { categories } = response.parsedBody;
        dispatch(setLuxeCategories(categories));
      }

      return response;
    } 
  } catch (error) {
    return error;
  }
};

export const getProducts = (): ThunkAction<
  ProductActionResponse<GetProductsResponse>,
  AppState,
  {},
  AnyAction
> => async (dispatch: ReduxDispatch, getState): ProductActionResponse<GetProductsResponse> => {
  try {
    if (getState().products.allProducts.length === 0) {
      const response = await get<GetProductsResponse>('/products', getState());
      if (response.parsedBody) {
        const { products } = response.parsedBody;
        dispatch(setProducts(products));
      }

      return response;
    }
  } catch (error) {
    return error;
  }
};

export const getLuxeProducts = (): ThunkAction<
  ProductActionResponse<GetLuxeProductsResponse>,
  AppState,
  {},
  AnyAction
> => async (dispatch: ReduxDispatch, getState): ProductActionResponse<GetLuxeProductsResponse> => {
  try {
    if (getState().products.luxeProducts.length === 0) {
      const response = await get<GetLuxeProductsResponse>('/products/luxe', getState());
      if (response.parsedBody) {
        const { products } = response.parsedBody;
        dispatch(setLuxeProducts(products));
      }

      return response;
    }
  } catch (error) {
    return error;
  }
};
