import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme: { colors } }): string => colors.grey};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
export const SpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 15px;
  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    width: 60%;
  }
`

export const ContentWrapper = styled.div`
  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    margin: auto;
    width: 60%;
  }
`;
export const InnerWrapper = styled.div`
  width: 100%;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    margin: auto;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 50px 0;
  flex-wrap: wrap;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    align-items: start;
    flex-direction: column;
    position: relative;
    width: 100%;
  }
`;

export const TitleContainer = styled.div`
  align-items: center
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
  width: 100%;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
  }
`;

export const CategoryTitleContainer = styled.div`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
  width: 60%;
`;

export const SvgContainer = styled.div`
  margin-right: 10px;
`;

export const Text = styled.div`
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 15px;
  text-align: center;
  color: ${({ theme: { colors } }): string => colors.primary};
  text-decoration: none;
`

export const Title = styled(Text)`
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 18px;
  font-weight: bold;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    font-size: 24px;
  }
`

export const Button = styled.button`
  border-radius: 20px;
  color: ${({ theme: { colors } }): string => colors.white};
  font-size: 15px;
  border: none;
  padding: 0 15px;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  height: 40px;
  cursor: pointer;
`;

export const SearchInput = styled.input`
  border-radius: 8px;
  border: none;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 14px;
  height: 48px;
  padding-left: 15px;
  width: 100%;
`;

export const SearchInputContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: 15px;
  padding: 0 5%;
  width: 100%;
  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    padding: 0;
    margin: auto;
    margin-top: 20px;
    width: 470px;
  }
`;

export const LabelInputContainer = styled.span`
  display: flex;
  margin-left: -30px;
`;

export const SubTitle = styled.div`
  color: ${({ theme: { colors } }): string => colors.lightGrey};
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 15px;
  margin-top: 10px;
  text-align: center;
`;

export const CategorySubTitle = styled.div`
  color: ${({ theme: { colors } }): string => colors.lightGrey};
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-size: 18px;
  margin-top: 10px;
  text-align: left;
`