import React, { Fragment, FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from '../../store';
import { Product } from '../../types/product';
import { CategoryMap } from '../../types/category';
import { CategoryLine } from './style/categoryList';
import ProductList from './productList';
import RightArrow from "../../images/right.svg";
import DownArrow from "../../images/down.svg";

interface CategoryListProps {
  categories: string[];
  products: {[key: string]: Product[]};
  luxe: boolean;
};

interface Folds {
  [key: string]: boolean;
}

const CategoryList: FC<CategoryListProps> = ({ categories, products, luxe }) => {
  const categoriesMap = useSelector<AppState, CategoryMap>(state => state.products.categories);
  const luxeCategoriesMap = useSelector<AppState, CategoryMap>(state => state.products.luxeCategories);
  const [unfolded, setUnfolded] = useState<Folds>({});
  const toggleUnfolded = (category: string): () => void => useCallback((): void => {
    setUnfolded({...unfolded, [category]: category in unfolded ? !unfolded[category] : true})
  }, [unfolded, setUnfolded]);

  return (
    <>
      {categories.map((category: string) => {
          return (
            <Fragment key={`category-${category}`}>
              <CategoryLine unfolded={unfolded[category]} onClick={toggleUnfolded(category)}>
                {luxe ? category in luxeCategoriesMap ? luxeCategoriesMap[category].name : "" : category in categoriesMap ? categoriesMap[category].name : ""}
                {unfolded[category] ? <DownArrow /> : <RightArrow />}
              </CategoryLine>
              {unfolded[category] ? <ProductList products={products[category]} /> : null}
            </Fragment>
          );
        })
      }
    </>
  );
}

export default CategoryList;
