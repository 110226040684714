import styled from 'styled-components';

interface CategoryLineProps {
  unfolded: boolean;
}

export const CategoryLine = styled.button<CategoryLineProps>`
  align-items: center;
  background-color: ${({ unfolded, theme: { colors } }): string => unfolded ? colors.grey : colors.white};
  border: none;
  display: flex;
  font-family: ${({ theme: { fonts } }): string => fonts.main };
  font-weight: bold;
  height: ${({ unfolded }): string => unfolded ? '30px' : '50px'};
  justify-content: space-between;
  margin: ${({ unfolded }): string => unfolded ? '0.5em 0 0 0' : '0.5em 0'};
  padding: 0 2em;
  width: 100%;
  border-radius: 10px;
  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    width: 60%;
  }
`
